var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Keuringen aanvragen","max-width":"tw-max-w-2xl"}},[_c('FormulateForm',{attrs:{"name":"certificationForm","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('fieldset',{staticClass:"fieldset-split-range !tw-flex !tw-flex-row !tw-gap-x-2 tw-items-center"},[_c('legend',[_vm._v(" Facturatie "),_c('span',{staticClass:"input-error"},[_vm._v("(verplicht)")])]),_c('FormulateInput',{attrs:{"name":"propertyOwner","type":"select","placeholder":"Kies een eigenaar","options":_vm.propertyOwners,"validation":!_vm.contact.id ? 'bail|required|completeAddress' : '',"validation-rules":{ completeAddress: _vm.completeAddress },"validation-messages":{
          required: 'Facturatiecontact is verplicht.',
          completeAddress: 'Facturatiecontact heeft geen (volledig) adres (vereist: straat, nummer, woonplaats)'
        },"outer-class":"tw-m-0 tw-w-1/2"},model:{value:(_vm.propertyOwner),callback:function ($$v) {_vm.propertyOwner=$$v},expression:"propertyOwner"}}),_vm._v(" OF "),_c('FormulateInput',{attrs:{"name":"contact","type":"autocomplete","auto-complete-type":"contact","placeholder":"Selecteer een contact","validation":!_vm.propertyOwner.id ? 'bail|required|completeAddress' : '',"validation-rules":{ completeAddress: _vm.completeAddress },"validation-messages":{
          required: 'Facturatiecontact is verplicht.',
          completeAddress: 'Facturatiecontact heeft geen (volledig) adres (vereist: straat, nummer, woonplaats)'
        },"outer-class":"tw-m-0 tw-w-1/2"},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}})],1),(_vm.linkedContacts.length)?_c('div',{staticClass:"tw-grid tw-grid-cols-1 tw-gap-4 tw-my-4"},[_c('FormulateInput',{attrs:{"label":"Bedrijfscontact","name":"linkedContact","type":"select","placeholder":"Kies een bedrijfscontact","options":_vm.linkedContacts,"validation":!_vm.contact.id ? 'bail|required|completeAddress' : '',"validation-rules":{ completeAddress: _vm.completeAddress },"validation-messages":{
          required: 'Facturatiecontact is verplicht.',
          completeAddress: 'Facturatiecontact heeft geen (volledig) adres (vereist: straat, nummer, woonplaats)'
        },"outer-class":"tw-m-0"},model:{value:(_vm.linkedContact),callback:function ($$v) {_vm.linkedContact=$$v},expression:"linkedContact"}})],1):_vm._e(),_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-4 tw-my-4"},[_c('FormulateInput',{attrs:{"name":"heating_type","type":"select","label":"Verwarmingstype","options":[_vm.heatingType],"disabled":"","error-behavior":"live","validation":"required","validation-messages":{
          required: 'Het kenmerk ‘Verwarmingsketel’ moet ingesteld staan op ‘Gemeenschappelijk’ of ‘Individueel’ om verder te kunnen gaan'
        },"show-required-label":true,"outer-class":"tw-m-0"},model:{value:(_vm.heatingType.value),callback:function ($$v) {_vm.$set(_vm.heatingType, "value", $$v)},expression:"heatingType.value"}}),_c('FormulateInput',{attrs:{"name":"transaction_type","type":"select","label":"Transactietype","options":[_vm.transactionType],"value":_vm.transactionType.value,"disabled":"","error-behavior":"live","validation":"required","validation-messages":{
          required: ("Keuringen kunnen niet worden aangevraagd voor panden met status '" + (_vm.entity.status_display) + "'")
        },"show-required-label":true,"outer-class":"tw-m-0"}})],1),_c('FormulateInput',{attrs:{"name":"inspection_types","type":"checkbox","label":"Keuringen","validation":"required","show-required-label":true,"options":_vm.INSPECTION_TYPES,"input-class":['tw-my-0'],"element-class":['tw-mt-1 tw-grid tw-grid-cols-2 tw-gap-y-1 tw-gap-x-4']}}),(_vm.heatingContactRequired)?_c('FormulateInput',{attrs:{"name":"heating_contact","type":"text","label":"Contactpersoon","validation":"bail|heatingContactIsRequired|max:200,length","validation-rules":{ heatingContactIsRequired: _vm.heatingContactIsRequired },"validation-messages":{
        heatingContactIsRequired: 'Contactpersoon is een verplicht veld',
        max: 'Contactpersoon mag maximaal 200 karakters lang zijn'
      },"show-required-label":_vm.heatingContactRequired}}):_vm._e(),_c('FormulateInput',{attrs:{"name":"visit_information","type":"textarea","label":"Bezoek informatie","value":_vm.entity.visit_arrangement,"validation":"required","validation-messages":{
        required: 'Bezoek informatie is een verplicht veld'
      },"show-required-label":true}}),_c('FormulateInput',{attrs:{"name":"extra_information","type":"textarea","label":"Extra informatie"}}),(_vm.values.inspection_types && _vm.values.inspection_types.includes('6'))?[_c('FormulateInput',{attrs:{"name":"oil_tank_location","type":"radio","label":"Stookolietank: locatie","options":{ bovengronds: 'Bovengronds', ondergronds: 'Ondergronds' },"value":"bovengronds"}}),_c('FormulateInput',{attrs:{"name":"oil_tank_access","type":"radio","label":"Stookolietank: vrij toegankelijk","options":{ ja: 'Ja', nee: 'Nee' },"value":"ja"}})]:_vm._e(),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":['tw-float-right']}},[_c('i',{class:[
          'fas tw-mr-2',
          isLoading ? 'fa-spinner-third fa-spin' : 'fa-cart-plus'
        ]}),_vm._v(" Doorsturen ")])]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }