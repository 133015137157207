<template>
  <Modal ref="modal" size="md" title="Wettelijke vermeldingen aanvragen.">
    <div>
      <div class="row">
        <div class="col-md-12">
          <p class="bg-warning b-r-sm" style="padding: 10px; font-weight: bold;">Opgelet! Zorg dat je de correcte percelen voor dit pand hebt geselecteerd</p>
          <label class="control-label">
            <input style="margin-top: 10px;" type="checkbox" v-model="regionOrder" :disabled="disableRegion">
            Gewestinfo
          </label>
          <button
            v-if="showResendButton(regionOrders)"
            type="button"
            title="Opnieuw versturen"
            class="btn btn-xs btn-primary tw-block tw-mb-1.5 m-left"
            @click="resendOrder(regionOrders[0])"
          >
            <i class="fa fa-refresh"></i> Aanvraag opnieuw versturen
          </button>
          <p class="m-left" v-for="existingRegionOrder in regionOrders" :key="existingRegionOrder.id">
            <span class="label" :class="orderStatus(existingRegionOrder.status).color">
              {{ orderStatus(existingRegionOrder.status).name }}
            </span> Besteld door {{ existingRegionOrder.ordered_by.first_name }} {{
            existingRegionOrder.ordered_by.last_name }} op {{ existingRegionOrder.created_on|datetime }}</p>
        </div>
        <div class="col-md-12">
          <label class="control-label">
            <input style="margin-top: 10px;" type="checkbox" v-model="heritageOrder" :disabled="disableHeritage">
            Onroerend erfgoed
          </label>
          <button
            v-if="showResendButton(heritageOrders)"
            type="button"
            title="Opnieuw versturen"
            class="btn btn-xs btn-primary tw-block tw-mb-1.5 m-left"
            @click="resendOrder(heritageOrders[0])"
          >
            <i class="fa fa-refresh"></i> Aanvraag opnieuw versturen
          </button>
          <p class="m-left" v-for="existingHeritageOrder in heritageOrders" :key="existingHeritageOrder.id">
            <span class="label" :class="orderStatus(existingHeritageOrder.status).color">
              {{ orderStatus(existingHeritageOrder.status).name }}
            </span> Besteld door {{ existingHeritageOrder.ordered_by.first_name }} {{
            existingHeritageOrder.ordered_by.last_name }} op {{ existingHeritageOrder.created_on|datetime }}</p>
        </div>
        <div class="col-md-12">
          <label class="control-label">
            <input style="margin-top: 10px;" type="checkbox" v-model="preemptionOrder" :disabled="disablePreemption">
            Recht van voorkoop
          </label>
          <button
            v-if="showResendButton(preemptionOrders)"
            type="button"
            title="Opnieuw versturen"
            class="btn btn-xs btn-primary tw-block tw-mb-1.5 m-left"
            @click="resendOrder(preemptionOrders[0])"
          >
            <i class="fa fa-refresh"></i> Aanvraag opnieuw versturen
          </button>
          <p class="m-left" v-for="existingPreemptionOrder in preemptionOrders" :key="existingPreemptionOrder.id">
            <span class="label" :class="orderStatus(existingPreemptionOrder.status).color">
              {{ orderStatus(existingPreemptionOrder.status).name }}
            </span> Besteld door {{ existingPreemptionOrder.ordered_by.first_name }} {{
            existingPreemptionOrder.ordered_by.last_name }} op {{ existingPreemptionOrder.created_on|datetime }}</p>
        </div>
        <div class="col-md-12">
          <label class="control-label">
            <input style="margin-top: 10px;" type="checkbox" v-model="environmentOrder" :disabled="disableEnvironment">
            Omgevingsrapport
          </label>
          <button
            v-if="showResendButton(environmentOrders)"
            type="button"
            title="Opnieuw versturen"
            class="btn btn-xs btn-primary tw-block tw-mb-1.5 m-left"
            @click="resendOrder(environmentOrders[0])"
          >
            <i class="fa fa-refresh"></i> Aanvraag opnieuw versturen
          </button>
          <p class="m-left" v-for="existingEnvironmentOrder in environmentOrders" :key="existingEnvironmentOrder.id">
            <span class="label" :class="orderStatus(existingEnvironmentOrder.status).color">
              {{ orderStatus(existingEnvironmentOrder.status).name }}
            </span> Besteld door {{ existingEnvironmentOrder.ordered_by.first_name }} {{
            existingEnvironmentOrder.ordered_by.last_name }} op {{ existingEnvironmentOrder.created_on|datetime }}</p>
        </div>
        <div class="col-md-12">
          <label class="control-label">
            <input style="margin-top: 10px;" type="checkbox" v-model="floodingOrder" :disabled="disableFlooding">
            Informatieplicht overstromingsgevaar
          </label>
          <button
            v-if="showResendButton(floodingOrders)"
            type="button"
            title="Opnieuw versturen"
            class="btn btn-xs btn-primary tw-block tw-mb-1.5 m-left"
            @click="resendOrder(floodingOrders[0])"
          >
            <i class="fa fa-refresh"></i> Aanvraag opnieuw versturen
          </button>
          <p class="m-left" v-for="existingFloodingOrder in floodingOrders" :key="existingFloodingOrder.id">
            <span class="label" :class="orderStatus(existingFloodingOrder.status).color">
              {{ orderStatus(existingFloodingOrder.status).name }}
            </span> Besteld door {{ existingFloodingOrder.ordered_by.first_name }} {{
            existingFloodingOrder.ordered_by.last_name }} op {{ existingFloodingOrder.created_on|datetime }}</p>
        </div>
        <div class="col-md-12">
          <label class="control-label">
            <input style="margin-top: 10px;" type="checkbox" v-model="orthophotoOrder" :disabled="disableOrthophoto">
            Luchtfoto's en historische kaarten
          </label>
          <button
            v-if="showResendButton(orthophotoOrders)"
            type="button"
            title="Opnieuw versturen"
            class="btn btn-xs btn-primary tw-block tw-mb-1.5 m-left"
            @click="resendOrder(orthophotoOrders[0])"
          >
            <i class="fa fa-refresh"></i> Aanvraag opnieuw versturen
          </button>
          <p class="m-left" v-for="existingOrthophotoOrder in orthophotoOrders" :key="existingOrthophotoOrder.id">
            <span class="label" :class="orderStatus(existingOrthophotoOrder.status).color">
              {{ orderStatus(existingOrthophotoOrder.status).name }}
            </span> Besteld door {{ existingOrthophotoOrder.ordered_by.first_name }} {{
            existingOrthophotoOrder.ordered_by.last_name }} op {{ existingOrthophotoOrder.created_on|datetime }}</p>
        </div>

        <div class="col-md-12">
          <label class="control-label">
            <input style="margin-top: 10px;" type="checkbox" v-model="uninhabitableOrder" :disabled="disableUninhabitable">
            Ongeschikt- en/of onbewoonbaarverklaring
          </label>
          <button
            v-if="showResendButton(uninhabitableOrders)"
            type="button"
            title="Opnieuw versturen"
            class="btn btn-xs btn-primary tw-block tw-mb-1.5 m-left"
            @click="resendOrder(uninhabitableOrders[0])"
          >
            <i class="fa fa-refresh"></i> Aanvraag opnieuw versturen
          </button>
          <p class="m-left" v-for="existingUninhabitableOrder in uninhabitableOrders" :key="existingUninhabitableOrder.id">
            <span class="label" :class="orderStatus(existingUninhabitableOrder.status).color">
              {{ orderStatus(existingUninhabitableOrder.status).name }}
            </span> Besteld door {{ existingUninhabitableOrder.ordered_by.first_name }} {{
            existingUninhabitableOrder.ordered_by.last_name }} op {{ existingUninhabitableOrder.created_on|datetime }}</p>
        </div>
        <div class="col-md-12">
          <label class="control-label">
            <input style="margin-top: 10px;" type="checkbox" v-model="conformityCertificateOrder" :disabled="disableConformityCertificate">
            Conformiteitsattest
          </label>
          <button
            v-if="showResendButton(conformityCertificateOrders)"
            type="button"
            title="Opnieuw versturen"
            class="btn btn-xs btn-primary tw-block tw-mb-1.5 m-left"
            @click="resendOrder(conformityCertificateOrders[0])"
          >
            <i class="fa fa-refresh"></i> Aanvraag opnieuw versturen
          </button>
          <p class="m-left" v-for="existingConformityCertificateOrder in conformityCertificateOrders" :key="existingConformityCertificateOrder.id">
            <span class="label" :class="orderStatus(existingConformityCertificateOrder.status).color">
              {{ orderStatus(existingConformityCertificateOrder.status).name }}
            </span> Besteld door {{ existingConformityCertificateOrder.ordered_by.first_name }} {{
            existingConformityCertificateOrder.ordered_by.last_name }} op {{ existingConformityCertificateOrder.created_on|datetime }}</p>
        </div>
        <div class="col-md-12">
          <label class="control-label">
            <input style="margin-top: 10px;" type="checkbox" v-model="recoveryClaimOrder" :disabled="disableRecoveryClaim">
            Herstelvorderingen
          </label>
          <button
            v-if="showResendButton(recoveryClaimOrders)"
            type="button"
            title="Opnieuw versturen"
            class="btn btn-xs btn-primary tw-block tw-mb-1.5 m-left"
            @click="resendOrder(recoveryClaimOrders[0])"
          >
            <i class="fa fa-refresh"></i> Aanvraag opnieuw versturen
          </button>
          <p class="m-left" v-for="existingRecoveryClaimOrder in recoveryClaimOrders" :key="existingRecoveryClaimOrder.id">
            <span class="label" :class="orderStatus(existingRecoveryClaimOrder.status).color">
              {{ orderStatus(existingRecoveryClaimOrder.status).name }}
            </span> Besteld door {{ existingRecoveryClaimOrder.ordered_by.first_name }} {{
            existingRecoveryClaimOrder.ordered_by.last_name }} op {{ existingRecoveryClaimOrder.created_on|datetime }}</p>
        </div>
      </div>
    </div>
    <div slot="footer">
      <button class="btn btn-link" data-dismiss="modal" type="button">Annuleren</button>
      <button @click="placeOrder" class="btn btn-primary" type="button"
              :disabled="!productSelected">Bestellen
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/iam/Modal'
import { loadOrdersForTodo } from '@/services/orders'

export default {
  name: 'LegalOrderModal',
  components: {
    Modal
  },
  props: {
    todo: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      orders: [],
      environmentOrder: false,
      environmentOrders: [],
      regionOrder: false,
      regionOrders: [],
      heritageOrder: false,
      heritageOrders: [],
      floodingOrder: false,
      floodingOrders: [],
      preemptionOrder: false,
      preemptionOrders: [],
      orthophotoOrder: false,
      orthophotoOrders: [],
      uninhabitableOrder: false,
      uninhabitableOrders: [],
      conformityCertificateOrder: false,
      conformityCertificateOrders: [],
      recoveryClaimOrder: false,
      recoveryClaimOrders: []
    }
  },
  computed: {
    /* Checks if a new product was ordered to handle the disabling of the order button */
    productSelected () {
      return this.floodingOrdered || this.preemptionOrdered || this.heritageOrdered || this.environmentOrdered || this.regionOrdered || this.orthophotoOrdered
    },
    floodingOrdered () {
      return this.productOrdered(this.floodingOrder, this.floodingOrders)
    },
    preemptionOrdered () {
      return this.productOrdered(this.preemptionOrder, this.preemptionOrders)
    },
    heritageOrdered () {
      return this.productOrdered(this.heritageOrder, this.heritageOrders)
    },
    environmentOrdered () {
      return this.productOrdered(this.environmentOrder, this.environmentOrders)
    },
    regionOrdered () {
      return this.productOrdered(this.regionOrder, this.regionOrders)
    },
    orthophotoOrdered () {
      return this.productOrdered(this.orthophotoOrder, this.orthophotoOrders)
    },
    uninhabitableOrdered () {
      return this.productOrdered(this.uninhabitableOrder, this.uninhabitableOrders)
    },
    conformityCertificateOrdered () {
      return this.productOrdered(this.conformityCertificateOrder, this.conformityCertificateOrders)
    },
    recoveryClaimOrdered () {
      return this.productOrdered(this.recoveryClaimOrder, this.recoveryClaimOrders)
    },
    disableFlooding () {
      return this.disableProduct(this.floodingOrder, this.floodingOrders)
    },
    disablePreemption () {
      return this.disableProduct(this.preemptionOrder, this.preemptionOrders)
    },
    disableEnvironment () {
      return this.disableProduct(this.environmentOrder, this.environmentOrders)
    },
    disableHeritage () {
      return this.disableProduct(this.heritageOrder, this.heritageOrders)
    },
    disableRegion () {
      return this.disableProduct(this.regionOrder, this.regionOrders)
    },
    disableOrthophoto () {
      return this.disableProduct(this.orthophotoOrder, this.orthophotoOrders)
    },
    disableUninhabitable () {
      return this.disableProduct(this.uninhabitableOrder, this.uninhabitableOrders)
    },
    disableConformityCertificate () {
      return this.disableProduct(this.conformityCertificateOrder, this.conformityCertificateOrders)
    },
    disableRecoveryClaim () {
      return this.disableProduct(this.recoveryClaimOrder, this.recoveryClaimOrders)
    }
  },
  methods: {
    async show () {
      await this.setUpOrders()
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    clear () {
      this.environmentOrder = false
      this.environmentOrders = []
      this.regionOrder = false
      this.regionOrders = []
      this.heritageOrder = false
      this.heritageOrders = []
      this.floodingOrder = false
      this.floodingOrders = []
      this.preemptionOrder = false
      this.preemptionOrders = []
      this.orthophotoOrder = false
      this.orthophotoOrders = []
      this.uninhabitableOrder = false
      this.uninhabitableOrders = []
      this.conformityCertificateOrder = false
      this.conformityCertificateOrders = []
      this.recoveryClaimOrder = false
      this.recoveryClaimOrders = []
    },
    /** This method handles the color and text of the progress label for each order
       *
       * @param status {integer}: status value of an order
       * */
    orderStatus (status) {
      switch (status) {
        case 1:
          return {
            name: 'Wordt verwerkt',
            color: 'label-default'
          }
        case 2:
          return {
            name: 'In behandeling',
            color: 'label-warning'
          }
        case 3:
          return {
            name: 'Voltooid',
            color: 'label-primary'
          }
        case 0:
          return {
            name: 'Mislukt',
            color: 'label-danger'
          }
      }
    },
    /** This method handles the default checkbox state for an order
       *
       * @param orders {array}: all orders of a specific type order, sorted by creation date (most recent first)
       * */
    checkboxState (orders) {
      if (orders[0]) {
        const currentOrder = orders[0]
        return [1, 2].includes(currentOrder.status)
      } else {
        return false
      }
    },
    /** This method is called on mount and sorts the passed orders by type and creation date, and sets the default
       * state for the checkboxes
       * */
    async setUpOrders () {
      const ordersResponse = await loadOrdersForTodo(this.todo.id)
      this.orders = ordersResponse.data.results
      if (this.orders) {
        this.clear()
        this.orders.forEach(order => {
          const orderData = order.data
          switch (orderData.type) {
            case 'legal.flooding.v1':
              this.floodingOrder = true
              this.floodingOrders.push(order)
              break
            case 'legal.preemption.v1':
              this.preemptionOrder = true
              this.preemptionOrders.push(order)
              break
            case 'legal.region.v1':
              this.regionOrder = true
              this.regionOrders.push(order)
              break
            case 'legal.heritage.v1':
              this.heritageOrder = true
              this.heritageOrders.push(order)
              break
            case 'legal.environment.v1':
              this.environmentOrder = true
              this.environmentOrders.push(order)
              break
            case 'legal.orthophoto.v1':
              this.orthophotoOrder = true
              this.orthophotoOrders.push(order)
              break
            case 'legal.uninhabitable.v1':
              this.uninhabitableOrder = true
              this.uninhabitableOrders.push(order)
              break
            case 'legal.conformitycertificate.v1':
              this.conformityCertificateOrder = true
              this.conformityCertificateOrders.push(order)
              break
            case 'legal.recoveryclaim.v1':
              this.recoveryClaimOrder = true
              this.recoveryClaimOrders.push(order)
              break
            default:
              break
          }
        })
      }
      this.prepareOrders()
    },
    prepareOrders () {
      const orderGroups = [this.heritageOrders, this.floodingOrders, this.environmentOrders, this.regionOrders, this.preemptionOrders, this.orthophotoOrders, this.uninhabitableOrders, this.conformityCertificateOrders, this.recoveryClaimOrders]
      orderGroups.forEach(orderGroup => {
        orderGroup.sort((a, b) => {
          return new Date(b.created_on) - new Date(a.created_on)
        })
      })
      this.heritageOrder = this.checkboxState(this.heritageOrders)
      this.floodingOrder = this.checkboxState(this.floodingOrders)
      this.environmentOrder = this.checkboxState(this.environmentOrders)
      this.regionOrder = this.checkboxState(this.regionOrders)
      this.preemptionOrder = this.checkboxState(this.preemptionOrders)
      this.orthophotoOrder = this.checkboxState(this.orthophotoOrders)
      this.uninhabitableOrder = this.checkboxState(this.uninhabitableOrders)
      this.conformityCertificateOrder = this.checkboxState(this.conformityCertificateOrders)
      this.recoveryClaimOrder = this.checkboxState(this.recoveryClaimOrders)
    },
    /** This method checks if a new product is ordered based on the current state of a checkbox and the existing orders
       *
       * @param checked {boolean}: state of a checkbox (checked or not checked)
       * @param orders {array}: all orders of a specific type order, sorted by creation date (most recent first)
       * */
    productOrdered (checked, orders) {
      // A new product is ordered if the checkbox is checked and either there are no existing orders or the most
      // recent existing order is finished or has failed
      return checked && (!orders[0] || (orders[0] && [0, 3].includes(orders[0].status)))
    },
    /** This method handles disabling of products for when an order is in progress
       *
       * @param checked {boolean}: state of a checkbox (checked or not checked)
       * @param orders {array}: all orders of a specific type order, sorted by creation date (most recent first)
       * */
    disableProduct (checked, orders) {
      return checked && orders[0] && [1, 2].includes(orders[0].status)
    },
    /** This method emits the orders that need to be created
       * */
    placeOrder () {
      const orders = []
      if (this.floodingOrdered) {
        orders.push({ type: 'legal.flooding.v1' })
      }
      if (this.preemptionOrdered) {
        orders.push({ type: 'legal.preemption.v1' })
      }
      if (this.environmentOrdered) {
        orders.push({ type: 'legal.environment.v1' })
      }
      if (this.heritageOrdered) {
        orders.push({ type: 'legal.heritage.v1' })
      }
      if (this.regionOrdered) {
        orders.push({ type: 'legal.region.v1' })
      }
      if (this.orthophotoOrdered) {
        orders.push({ type: 'legal.orthophoto.v1' })
      }
      if (this.uninhabitableOrdered) {
        orders.push({ type: 'legal.uninhabitable.v1' })
      }
      if (this.conformityCertificateOrdered) {
        orders.push({ type: 'legal.conformitycertificate.v1' })
      }
      if (this.recoveryClaimOrdered) {
        orders.push({ type: 'legal.recoveryclaim.v1' })
      }
      this.$emit('orderPlaced', orders)
      this.hide()
      this.clear()
    },
    async resendOrder (order) {
      this.$emit('orderResend', order)
      this.hide()
      this.clear()
    },
    showResendButton (orders) {
      return orders.length ? orders[0].status === 0 : false
    }
  }
}
</script>

<style scoped>
  .m-left {
    margin-left: 17px;
  }

  span.label {
    margin-right: 15px;
  }
</style>
