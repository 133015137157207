import { render, staticRenderFns } from "./VipOrderModal.vue?vue&type=template&id=65fa156f&scoped=true&"
import script from "./VipOrderModal.vue?vue&type=script&lang=js&"
export * from "./VipOrderModal.vue?vue&type=script&lang=js&"
import style0 from "./VipOrderModal.vue?vue&type=style&index=0&id=65fa156f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65fa156f",
  null
  
)

export default component.exports