var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"VIP","max-width":"tw-max-w-2xl"},on:{"hide":_vm.onHide}},[_c('FormulateForm',{attrs:{"name":"vipOrderForm","inert":_vm.order && !_vm.order.can_retry,"errors":_vm.validationErrors},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('h2',{staticClass:"tw-mt-0 tw-mb-4"},[_vm._v(" "+_vm._s(_vm.property.reference)+" - "+_vm._s(_vm.property.address)+" ")]),(_vm.order)?_c('div',_vm._l((_vm.parcels),function(ref){
var building_units = ref.building_units;
var capakey = ref.capakey;
return _c('div',{key:'parcel_building_units_' + capakey,staticClass:"tw-my-2"},[_c('span',{staticClass:"tw-font-semibold tw-text-sm"},[_vm._v(" Perceel "+_vm._s(capakey)+": ")]),_c('ul',{staticClass:"tw-ml-1"},_vm._l((building_units),function(unit){return _c('li',{key:unit.id},[_c('i',{staticClass:"far fa-arrow-right tw-mr-1"}),_vm._v(" "+_vm._s(unit.label)+" ")])}),0)])}),0):_c('FormulateInput',{attrs:{"type":"group","name":"parcels","value":_vm.parcels,"validation":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var index = ref.index;
return [(model[index])?_c('div',{staticClass:"tw-my-4"},[_c('FormulateInput',{attrs:{"type":"checkbox","name":model[index].capakey,"disabled":model[index].disabled,"label":("Perceel " + (model[index].capakey)),"label-class":['tw-text-base tw-font-light'],"element-class":"tw-w-auto tw-inline-block tw-align-baseline","outer-class":"tw-my-0"},on:{"input":function($event){return _vm.capakeySelected(
              $event,
              model[index]
            )}}}),_c('FormulateInput',{attrs:{"type":"checkbox","name":"building_unit_ids","options":model[index].building_units,"outer-class":"tw-pl-6 tw-border-l"},on:{"input":function($event){return _vm.buildingUnitSelected(
              $event,
              model[index]
            )}}})],1):_vm._e()]}}])}),_c('FormulateInput',{attrs:{"type":"textarea","name":"explanation","label":"Toelichting","placeholder":"Toelichting"}}),_c('h2',{staticClass:"tw-mb-0 tw-mt-8"},[_vm._v(" Opvragen ")]),_c('FormulateInput',{attrs:{"type":"checkbox","name":"product_ids","options":_vm.products,"validation":"min:1,length","validation-messages":{
        min: 'Selecteer minstens 1 type'
      },"input-class":"tw-my-1"}}),(_vm.order && _vm.order.data)?[_c('h2',{staticClass:"tw-mt-8 tw-mb-4"},[_vm._v(" Bestellingen ("+_vm._s(_vm._f("datetime")(_vm.order.ordered_on))+") "),_c('span',{class:['status tw-my-auto !tw-ml-0', _vm.ORDER_STATUS_CLASSES[_vm.order.status]]},[_vm._v(" "+_vm._s(_vm.ORDER_STATUS_LABELS[_vm.order.status])+" ")])]),_vm._l((_vm.order.data.dossiers),function(dossier){return _c('div',{key:'parcel_dossier_' + dossier.capakey,staticClass:"tw-mb-4"},[_c('span',{staticClass:"tw-text-base"},[_vm._v(" Perceel "+_vm._s(dossier.capakey)+" ")]),_c('span',{class:['status tw-rounded-md', _vm.DOSSIER_STATUS_CLASSES[dossier.status]]},[_vm._v(" "+_vm._s(dossier.status)+" ")]),_vm._l((dossier.records),function(records,productId){return _c('div',{key:productId,staticClass:"tw-mb-2"},[_c('span',{staticClass:"tw-font-semibold tw-text-sm"},[_c('i',{staticClass:"far fa-arrow-right tw-mr-1"}),_vm._v(" "+_vm._s(_vm.getProductLabelFromId(productId))+" ")]),_c('ul',{staticClass:"tw-ml-2 tw-pl-3 tw-border-l"},_vm._l((records),function(record,index){return _c('li',{key:index,staticClass:"tw-my-0.5"},[_vm._v(" "+_vm._s(record.label)+" "),_c('span',{class:['status', _vm.RECORD_STATUS_CLASSES[record.status]]},[_vm._v(" "+_vm._s(_vm.RECORD_STATUS_LABELS[record.status])+" ")])])}),0)])})],2)})]:_vm._e(),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-justify-end tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading || _vm.order}},[_c('i',{class:[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-cart-plus'
          ]}),_vm._v(" Doorsturen ")]),(_vm.order && _vm.order.can_retry)?_c('FormulateInput',{attrs:{"type":"button","disabled":_vm.retryInProgress},on:{"click":_vm.retryVIPOrder}},[_c('i',{class:[
            'fas tw-mr-2',
            _vm.retryInProgress ? 'fa-spinner-third fa-spin' : 'fa-repeat'
          ]}),_vm._v(" Probeer opnieuw ")]):_vm._e()],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }