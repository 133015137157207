import { httpClient } from './axiosClients'
import { commaSerializer } from './paramSerializers'

export function getCategories () {
  return httpClient.get('/api/todos/checklist/overview/categories?page_size=50')
}

export function getChecklistTemplates (params) {
  return httpClient.get('/api/todos/checklist/templates', { params })
}

export function createChecklist (payload) {
  return httpClient.post('/api/todos/checklists', payload)
}

export function updateTodo (todoId, payload) {
  return httpClient.patch(`/api/todos/todo/${todoId}`, payload)
}

export function loadPropertyChecklistTemplate (propertyId, payload) {
  return httpClient.post(`/api/property/${propertyId}/checklist/load`, payload)
}

export function loadProjectChecklistTemplate (projectId, payload) {
  return httpClient.post(`/api/project/${projectId}/checklist/load`, payload)
}

export function getChecklistOverview (payload) {
  const { url = '/api/todos/checklist/v2/overview', params } = payload
  return httpClient.get(url, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function moveTodo (todoId, payload) {
  // The accepted values are to_do, in_progress, done. In the following payload:
  // {
  //   action: "to_do"
  // }
  return httpClient.post(`/api/todos/todo/${todoId}/move`, payload)
}

export function propertyBulkEditTodos (propertyId, payload) {
  return httpClient.post(`/api/property/${propertyId}/checklist/bulk-edit`, payload)
}

export function projectBulkEditTodos (projectId, payload) {
  return httpClient.post(`/api/project/${projectId}/checklist/bulk-edit`, payload)
}

export function getPropertyChecklistV2 (propertyId, params) {
  return httpClient.get(`/api/property/${propertyId}/checklist/v2`, { params })
}

export function getProjectChecklistV2 (projectId, params) {
  return httpClient.get(`/api/project/${projectId}/checklist/v2`, { params })
}

export function sendPropertyChecklistNotification (propertyId, payload) {
  return httpClient.post(`/api/property/${propertyId}/send-checklist-notification`, payload)
}

export function sendProjectChecklistNotification (projectId, payload) {
  return httpClient.post(`/api/project/${projectId}/send-checklist-notification`, payload)
}

export function propertyCreateCustomTodo (propertyId, payload) {
  return httpClient.post(`/api/property/${propertyId}/create-custom-todo`, payload)
}

export function projectCreateCustomTodo (projectId, payload) {
  return httpClient.post(`/api/project/${projectId}/create-custom-todo`, payload)
}

export function deleteTodo (todoId) {
  return httpClient.delete(`/api/todos/todo/${todoId}`)
}
